import { Button, useDisclosure } from '@chakra-ui/react';
import { Icon, StackDialog } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

export const PlayerMenuInfo: React.FC = () => {
  const { onClose, onOpen, isOpen } = useDisclosure();

  return (
    <>
      <IconContainer>
        <Button
          variant="inputButton"
          background="transparent"
          color="text.placeholder"
          onClick={onOpen}
        >
          <Icon name="question-circle" size={rawDimensions.size20} />
        </Button>
      </IconContainer>
      <StackDialog
        title="What is a player menu?"
        isOpen={isOpen}
        onClose={onClose}
      >
        <Container>
          <AppImage src="https://liwwunutcahympidrjyx.supabase.co/storage/v1/object/public/images/cms/player_menu_app.png" />
          <DetailsContainer>
            <Title>Player menu</Title>
            <Description>
              A player menu shows important game stats like health, score, or
              gold on your screen. It keeps players updated without interrupting
              the game. You can display up to 4 number or item quantities to
              help track changes during gameplay.
            </Description>
          </DetailsContainer>
        </Container>
      </StackDialog>
    </>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

const Container = styled.div`
  background: radial-gradient(
    ellipse,
    rgba(28, 49, 166, 1) 0%,
    rgba(12, 43, 62, 1) 65%,
    rgba(33, 33, 44, 1) 80%,
    rgba(12, 12, 12, 1) 100%
  );
  background-image: url('https://liwwunutcahympidrjyx.supabase.co/storage/v1/object/public/images/cms/player_menu_bg.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
`;

const AppImage = styled.img`
  width: 390px;
  height: 380px;
  margin: 0 auto;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  align-items: center;
  padding: ${dimensions.size48};
  text-align: center;
`;

const Title = styled.div`
  ${textStyles.title.t18b}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Description = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.body};
`;
