import { Button, ButtonGroup } from '@chakra-ui/react';
import { useFeatureFlagEvaluation } from '@maestro/feature-flags';
import { dimensions, textStyles } from '@maestro/styles';
import { toSupabaseClient } from '@maestro/supabase';
import { useCreate, useGetIdentity } from '@refinedev/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Identity } from '../../auth';
import { Screen } from '../../components/Screen';
import { LocationGenerator } from './components/CreateWorld/LocationGenerator';
import { NpcsGenerator } from './components/CreateWorld/NpcsGenerator';
import { WorldBaseFieldsStep } from './components/CreateWorld/WorldBaseFieldsStep';
import { CreateWorldAiHelper } from './components/CreateWorldAiHelper';
import { useGeneratedWorldItemsState } from './hooks/useGeneratedWorldItemsState';
import {
  CREATE_WORLD_STEPS,
  CreateWorldFields,
  WorldCharacter,
  WorldLocation,
} from './types';
import { WorldCreatedPrompt } from './WorldCreatedPrompt';

export const WorldCreate: React.FC = () => {
  const [step, setStep] = useState<CREATE_WORLD_STEPS>(
    CREATE_WORLD_STEPS.BASE_FIELDS,
  );
  const [isLoading, setIsLoading] = useState(false);
  const { data: identity } = useGetIdentity<Identity>();
  const [isGeneratingContent, setIsGeneratingContent] =
    useState<boolean>(false);

  const { mutateAsync } = useCreate();
  const { getFlagEvaluation } = useFeatureFlagEvaluation();
  const isGenerateWithAiEnabled = getFlagEvaluation('ENABLE-GENERATE-WITH-AI');

  const scrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const [world, setWorld] = useState<
    Pick<CreateWorldFields, 'title' | 'story' | 'coverImage'>
  >({ title: '', story: '', coverImage: '' });
  const { characters, setCharacters, locations, setLocations } =
    useGeneratedWorldItemsState();
  const [submitPressed, setSubmitPressed] = useState<boolean>(false);

  const isWorldValid = () => {
    return !!world.coverImage && !!world.story && !!world.title;
  };

  const isCharactersValid = () => {
    return characters.every(
      ({ name, traits, goals, relationships }) =>
        !!name && !!traits && !!goals && !!relationships,
    );
  };

  const isLocationsValid = () => {
    return locations.every(
      ({ name, description, npcs }) =>
        name.length > 0 && description.length > 0 && npcs.length > 0,
    );
  };

  const goBack = () => {
    if (step === CREATE_WORLD_STEPS.NPCS) {
      setStep(CREATE_WORLD_STEPS.BASE_FIELDS);
      scrollTop();
    } else if (step === CREATE_WORLD_STEPS.LOCATIONS) {
      setStep(CREATE_WORLD_STEPS.NPCS);
      scrollTop();
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);

    await mutateAsync({
      resource: 'world',
      values: {
        title: world.title,
        source_story: world.story,
        locations,
        characters,
        media: { main: world.coverImage, thumbnail: world.coverImage },
        created_by_id: identity?.internalId ?? null,
      },
    }).finally(() => setIsLoading(false));

    // mutate doesn't return the created object, so we need to fetch it
    const client = toSupabaseClient();
    const { data } = await client
      .from('world')
      .select('id, title')
      .order('created_at', { ascending: false });

    if (data) {
      setCreatedWorld(data[0]);
    }
  };

  const nextStep = async () => {
    setSubmitPressed(false);

    if (step === CREATE_WORLD_STEPS.BASE_FIELDS && isWorldValid()) {
      setStep(CREATE_WORLD_STEPS.NPCS);
      scrollTop();
    } else if (step === CREATE_WORLD_STEPS.NPCS && isCharactersValid()) {
      setStep(CREATE_WORLD_STEPS.LOCATIONS);
      scrollTop();
    } else if (step === CREATE_WORLD_STEPS.LOCATIONS && isLocationsValid()) {
      await onSubmit();
    } else {
      setSubmitPressed(true);
    }
  };

  const [createdWorld, setCreatedWorld] = useState<
    { id: string; title: string } | undefined
  >();

  return (
    <Screen title="Create World" backPath="/">
      <WorldCreatedPrompt
        isOpen={!!createdWorld}
        onClose={() => setCreatedWorld(undefined)}
        worldId={createdWorld?.id}
        worldTitle={createdWorld?.title}
      />
      <Container>
        <StepTitle>
          <Text>
            {step === CREATE_WORLD_STEPS.BASE_FIELDS &&
              'Step 1: Create Your World'}
            {step === CREATE_WORLD_STEPS.NPCS &&
              'Step 2: Add Characters to your world'}
            {step === CREATE_WORLD_STEPS.LOCATIONS &&
              'Step 3: Add locations to your world'}
          </Text>
          {isGenerateWithAiEnabled && (
            <CreateWorldAiHelper
              step={step}
              onChange={setWorld}
              setCharacters={(characters: WorldCharacter[]) =>
                setCharacters((state) =>
                  state.length === 1 &&
                  !state[0].goals.length &&
                  !state[0].name.length &&
                  !state[0].relationships.length &&
                  !state[0].traits.length
                    ? [...characters]
                    : [...state, ...characters],
                )
              }
              setLocations={(locations: WorldLocation[]) =>
                setLocations((state) =>
                  state.length === 1 &&
                  !state[0].name.length &&
                  !state[0].description.length &&
                  !state[0].npcs.length
                    ? [...locations]
                    : [...state, ...locations],
                )
              }
              world={world}
              characters={characters}
            />
          )}
        </StepTitle>

        {step === CREATE_WORLD_STEPS.BASE_FIELDS && (
          <WorldBaseFieldsStep
            world={world}
            onChange={setWorld}
            submitPressed={submitPressed}
          />
        )}
        {step === CREATE_WORLD_STEPS.NPCS && (
          <NpcsGenerator
            submitPressed={submitPressed}
            story={world.story ?? ''}
            locations={locations}
            characters={characters}
            onCharacterChange={(nextCharacter, index) =>
              setCharacters((state) =>
                state.map((c, i) => (i === index ? nextCharacter : c)),
              )
            }
            onCharacterDelete={(index) =>
              setCharacters((state) => state.filter((_, i) => i !== index))
            }
            onNewCharacter={() =>
              setCharacters([
                ...characters,
                {
                  name: '',
                  traits: '',
                  goals: '',
                  relationships: '',
                },
              ])
            }
            onGeneratingContent={(generating: boolean) =>
              setIsGeneratingContent(generating)
            }
          />
        )}
        {step === CREATE_WORLD_STEPS.LOCATIONS && (
          <LocationGenerator
            submitPressed={submitPressed}
            story={world.story ?? ''}
            locations={locations}
            characters={characters}
            onLocationChange={(nextLocation, index) =>
              setLocations((state) =>
                state.map((l, i) => (i === index ? nextLocation : l)),
              )
            }
            onLocationDelete={(index) =>
              setLocations((state) => state.filter((_, i) => i !== index))
            }
            onNewLocation={() =>
              setLocations((state) => [
                ...state,
                {
                  name: '',
                  description: '',
                  npcs: '',
                },
              ])
            }
            onGeneratingContent={(generating: boolean) =>
              setIsGeneratingContent(generating)
            }
          />
        )}
        <ButtonGroup
          mt={dimensions.size32}
          display={'flex'}
          flexFlow={'row'}
          justifyContent={'flex-end'}
        >
          {[CREATE_WORLD_STEPS.LOCATIONS, CREATE_WORLD_STEPS.NPCS].includes(
            step,
          ) && (
            <Button
              onClick={() => goBack()}
              variant="outline"
              size="md"
              isDisabled={isLoading || isGeneratingContent}
            >
              Back
            </Button>
          )}
          <Button
            isDisabled={isLoading || isGeneratingContent}
            onClick={() => nextStep()}
            variant="primary"
            size="md"
          >
            {CREATE_WORLD_STEPS.LOCATIONS === step
              ? 'Save & Create World'
              : 'Next'}
          </Button>
        </ButtonGroup>
      </Container>
    </Screen>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size32};
`;

const StepTitle = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.div`
  ${textStyles.title.t20b}
  color: ${({ theme }) => theme.colors.text.header};
`;
