import type { Message } from '../game/messages.types';
import { StatefulGame } from './statefulGame';

export const onCharacterCreated = async (
  game: StatefulGame,
): Promise<Message[]> => {
  const characterCreationNode = game.gameNodes.characterCreationNode();

  return characterCreationNode && characterCreationNode.nextNodeId
    ? game.execute(characterCreationNode.nextNodeId)
    : [];
};
