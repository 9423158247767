import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useGenerateWorldItems } from '../../hooks/useGenerateWorldItems';

type Fields = {
  count: number;
};

type Props = {
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  setWorldItems: (items: Record<string, unknown>) => void;
  onClose?: () => void;
  setLoadingState?: (loading: boolean) => void;
  itemType: 'character' | 'location';
  story: string;
  worldContext: Record<string, unknown>;
};

export const GenerateWorldItemWithAiForm: FC<Props> = ({
  submitButtonRef,
  setWorldItems,
  onClose,
  setLoadingState,
  itemType,
  story,
  worldContext,
}) => {
  const [isInteractingWithAi, setIsInteractingWithAi] =
    useState<boolean>(false);

  const toast = useToast();

  const { generateWorldItems } = useGenerateWorldItems();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Fields>();

  const onGenerateClick: SubmitHandler<Fields> = async (data) => {
    try {
      if (!data.count) {
        toast({
          title: 'A count is required',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });

        return;
      }

      setIsInteractingWithAi(true);
      setLoadingState?.(true);

      toast({
        title: 'Making magic with AI 🧙‍♂️',
        status: 'loading',
        duration: null,
        isClosable: false,
        id: 'world-bible-generate-toast',
      });

      const { items } = await generateWorldItems({
        count: data.count,
        itemType,
        story,
        worldContext,
      });

      setWorldItems(items);

      toast.close('world-bible-generate-toast');

      toast({
        title: `World ${itemType}s generated`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose?.();
    } catch (error) {
      toast.close('world-bible-generate-toast');

      toast({
        title: 'Error generating world items',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsInteractingWithAi(false);
      setLoadingState?.(false);
    }
  };

  return (
    <form method="dialog" onSubmit={handleSubmit(onGenerateClick)}>
      <FormControl mb="3" isInvalid={!!errors?.count}>
        <FormLabel>Number of {itemType}s</FormLabel>
        <Input
          id="count"
          type="number"
          {...register('count', { required: 'A count is required' })}
        />
        <FormErrorMessage>{`${errors.count?.message}`}</FormErrorMessage>
      </FormControl>
      <Button
        display={'none'}
        isLoading={isInteractingWithAi}
        type="submit"
        variant="primary"
        ref={submitButtonRef}
      />
    </form>
  );
};
