import { Button, ModalFooter, Switch, useDisclosure } from '@chakra-ui/react';
import {
  DataType,
  PropertyScope,
  RpgConfig,
  RpgConfigProperty,
} from '@common/studio-types';
import {
  Field,
  Icon,
  TextInput,
  StackDialog,
  ModalBody,
  ListItem,
  ListItemColumn,
} from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { SmallHint } from '../Hint';
import { EnumPropertyForm } from './EnumPropertyFormV2';
import { EnumValues } from './EnumValues';
import { NumberPropertyForm } from './NumberPropertyFormV2';
import { StringPropertyForm } from './StringPropertyFormV2';

type PropertyFormProps = {
  onChange: (data: RpgConfigProperty) => void;
  data: RpgConfigProperty;
  onRemove: () => void;
  rpgConfig: RpgConfig;
};

const icons: Record<DataType, string> = {
  [DataType.Number]: 'number-type',
  [DataType.String]: 'text-type',
  [DataType.Enum]: 'enum-type',
};

export const PropertyForm: React.FC<PropertyFormProps> = (props) => {
  const { isOpen, onToggle } = useDisclosure();
  const { onRemove, rpgConfig } = props;
  const [localData, setLocalData] = useState({ ...props.data });
  const icon = icons[localData.config.dataType];

  const onSave = () => {
    props.onChange(localData);
    onToggle();
  };

  const onEditClick = () => {
    onToggle();
    setLocalData({ ...props.data });
  };

  useEffect(() => {
    setLocalData({ ...props.data });
  }, [props.data]);

  const description = useMemo(() => {
    switch (props.data.config.dataType) {
      case DataType.Number:
        return props.data.config.defaultValue;
      case DataType.String:
        return props.data.config.defaultValue;
      case DataType.Enum:
        return props.data.config.options.length ? (
          <EnumValues options={props.data.config.options} />
        ) : undefined;
    }
  }, [props.data]);

  const onScopeChange = (shouldResetOnEpisode: boolean) => {
    const scope: PropertyScope = shouldResetOnEpisode
      ? PropertyScope.Episode
      : PropertyScope.Series;

    setLocalData({ ...localData, scope });
  };

  return (
    <>
      <ListItem onClick={onEditClick}>
        <ListItemColumn width="30%">
          <Icon name={icon} size={rawDimensions.size16} />
          {props.data.name || <Empty>Empty</Empty>}
        </ListItemColumn>
        <ListItemColumn flex>
          {description || <Empty>Empty</Empty>}
        </ListItemColumn>
        <ListItemColumn reverse width={dimensions.size120}>
          <Button
            variant="inputButton"
            background="transparent"
            color={isOpen ? 'text.header' : 'text.placeholder'}
            isActive={isOpen}
          >
            <Icon name="edit" size={rawDimensions.size20} />
          </Button>

          <Button
            variant="inputButton"
            background="transparent"
            color="text.placeholder"
            onClick={onRemove}
          >
            <Icon name="trash" size={rawDimensions.size20} />
          </Button>
        </ListItemColumn>
      </ListItem>
      <StackDialog title="Edit Property" isOpen={isOpen} onClose={onToggle}>
        <ModalBody>
          <DetailsContainer>
            <Field label="Name">
              <TextInput
                leftIcon={<Icon name={icon} size={rawDimensions.size16} />}
                placeholder="Name"
                hint="You can use text properties to store character names, quest titles, or professions."
                maxCharacters={20}
                isInvalid={!localData.name}
                value={localData.name}
                onChange={(e) =>
                  setLocalData({ ...localData, name: e.target.value })
                }
              />
            </Field>
            {localData.config.dataType === DataType.Number && (
              <NumberPropertyForm
                propertyId={localData.id}
                rpgConfig={rpgConfig}
                data={localData.config}
                onChange={(config) => setLocalData({ ...localData, config })}
              />
            )}
            {localData.config.dataType === DataType.String && (
              <StringPropertyForm
                data={localData.config}
                onChange={(config) => setLocalData({ ...localData, config })}
              />
            )}
            {localData.config.dataType === DataType.Enum && (
              <EnumPropertyForm
                data={localData.config}
                onChange={(config) => setLocalData({ ...localData, config })}
              />
            )}
            <Field
              label="Reset property every episode"
              rightButton={
                <Switch
                  isChecked={localData.scope === PropertyScope.Episode}
                  onChange={(e) => onScopeChange(e.target.checked)}
                  size="lg"
                />
              }
            >
              <SmallHint>
                Selecting this will reset the value of this property when the
                episode is complete.
              </SmallHint>
            </Field>
          </DetailsContainer>
        </ModalBody>
        <ModalFooter>
          <Button variant="default" onClick={onRemove}>
            Delete
          </Button>
          <Divider />
          <Button variant="default" onClick={onToggle}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </ModalFooter>
      </StackDialog>
    </>
  );
};

const Divider = styled.div`
  flex: 1;
`;

const Empty = styled.div`
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

const DetailsContainer = styled.div`
  width: 100%;
  padding: ${dimensions.size16};
`;
