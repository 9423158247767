import type { LocationUpdateNodeData } from '@common/studio-types';
import type { GameState } from '../game';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';

export const locationUpdate = (
  node: GameNode<LocationUpdateNodeData>,
  state: GameState,
): ActionResult => {
  return {
    messages: [{ type: 'location-update', locationName: node.locationName }],
    state,
  };
};
