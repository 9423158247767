import { Button, ModalFooter } from '@chakra-ui/react';
import {
  PropertyScope,
  RpgConfig,
  RpgConfigProperty,
} from '@common/studio-types';
import {
  Icon,
  List,
  ListBody,
  ListHeader,
  ListHeaderColumn,
  ModalBody,
} from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AddPropertyButton } from '../../propertyForm/AddPropertyButtonV2';
import { PropertyForm } from '../../propertyForm/PropertyFormV2';
import { PropertiesFormEmptyState } from './PropertiesFormEmptyState';

type Props = {
  properties: RpgConfigProperty[];
  episodeScopedOnly?: boolean;
  rpgConfig: RpgConfig;
  onChange: (data: RpgConfigProperty[]) => void;
};

export const PropertiesForm: React.FC<Props> = ({
  properties,
  episodeScopedOnly,
  rpgConfig,
  onChange,
}) => {
  const [localProperties, setLocalProperties] = useState<RpgConfigProperty[]>([
    ...properties,
  ]);

  useEffect(() => setLocalProperties(properties), [properties]);

  const onCreateProperty = (property: RpgConfigProperty) => {
    const newProperties = [...localProperties, property];
    setLocalProperties(newProperties);
    onChange(newProperties);
  };

  const onPropertyChange = (data: RpgConfigProperty) => {
    const newProperties = localProperties.map((prop) =>
      prop.id === data.id ? data : prop,
    );
    setLocalProperties(newProperties);
    onChange(newProperties);
  };
  const onRemoveProperty = (data: RpgConfigProperty) => {
    const newProperties = localProperties.filter((p) => p.id !== data.id);
    setLocalProperties(newProperties);
    onChange(newProperties);
  };

  const localRpgConfig: RpgConfig = useMemo(
    () => ({ ...rpgConfig, properties: localProperties }),
    [rpgConfig, localProperties],
  );

  return (
    <SeriesForm>
      <ModalBody>
        <Container>
          {localProperties.length === 0 && (
            <PropertiesFormEmptyState onCreateProperty={onCreateProperty} />
          )}

          {localProperties.length > 0 && (
            <List>
              <ListHeader>
                <ListHeaderColumn width="30%">Name</ListHeaderColumn>
                <ListHeaderColumn flex>Description</ListHeaderColumn>
                <ListHeaderColumn width={dimensions.size120} />
              </ListHeader>
              <ListBody>
                {localProperties.map((attribute) => (
                  <PropertyForm
                    rpgConfig={localRpgConfig}
                    key={attribute.id}
                    data={attribute}
                    onChange={onPropertyChange}
                    onRemove={() => onRemoveProperty(attribute)}
                  />
                ))}
              </ListBody>
            </List>
          )}
        </Container>
      </ModalBody>
      <ModalFooter justifyContent="start">
        <AddPropertyButton
          scope={
            episodeScopedOnly ? PropertyScope.Episode : PropertyScope.Series
          }
          onAddProperty={onCreateProperty}
        >
          <Button
            leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
            variant="default"
          >
            Add Property
          </Button>
        </AddPropertyButton>
      </ModalFooter>
    </SeriesForm>
  );
};

const SeriesForm = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${dimensions.size640};
  overflow-y: auto;
  padding: ${dimensions.size8} ${dimensions.size0};
`;
