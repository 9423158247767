import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import {
  Message,
  isCoinTossInteractionMessage,
  isCoinTossResultMessage,
  isDiceRollInteractionMessage,
  isDiceRollResultMessage,
  isEndEpisodeMessage,
  isImageMessage,
  isInventoryItemChangeMessage,
  isObjectiveCompletedMessage,
  isPlayerAttributeMessage,
  isPlayerInputMessage,
  isPlayerInputResultMessage,
  isSelectedInteractionOptionMessage,
  isSingleSelectInteractionMessage,
  isStatBlockMessage,
  isTextMessage,
} from '@enigma-engine/core';
import { GameSimulator } from '../hooks/useGameSimulator';
import { messageAnimation } from './keyframes';
import { MaestroGameMessageCoinTossInteraction } from './MaestroGameMessageCoinTossInteraction';
import { MaestroGameMessageCoinTossResult } from './MaestroGameMessageCoinTossResult';
import { MaestroGameMessageDiceRollInteraction } from './MaestroGameMessageDiceRollInteraction';
import { MaestroGameMessageDiceRollResult } from './MaestroGameMessageDiceRollResult';
import { MaestroGameMessageEndEpisode } from './MaestroGameMessageEndEpisode';
import { MaestroGameMessageImage } from './MaestroGameMessageImage';
import { MaestroGameMessageInventoryItemChange } from './MaestroGameMessageInventoryItemChange';
import { MaestroGameMessageNarratorText } from './MaestroGameMessageNarratorText';
import { MaestroGameMessageObjectiveCompleted } from './MaestroGameMessageObjectiveCompleted';
import { MaestroGameMessagePlayerAttribute } from './MaestroGameMessagePlayerAttribute';
import { MaestroGameMessagePlayerChoice } from './MaestroGameMessagePlayerChoice';
import { MaestroGameMessagePlayerInput } from './MaestroGameMessagePlayerInput';
import { MaestroGameMessagePlayerInputResult } from './MaestroGameMessagePlayerInputResult';
import { MaestroGameMessageSelectedPlayerChoice } from './MaestroGameMessageSelectedPlayerChoice';
import { MaestroGameMessageStatBlock } from './MaestroGameMessageStatBlock';

type Props = {
  message: Message;
  game: GameSimulator;
  isLastMessage: boolean;
};

const Component: React.FC<Props> = ({ message, game, isLastMessage }) => {
  if (isTextMessage(message)) {
    return <MaestroGameMessageNarratorText message={message} />;
  }

  if (isSingleSelectInteractionMessage(message)) {
    return (
      <MaestroGameMessagePlayerChoice
        game={game}
        message={message}
        isLastMessage={isLastMessage}
      />
    );
  }

  if (isSelectedInteractionOptionMessage(message)) {
    return <MaestroGameMessageSelectedPlayerChoice message={message} />;
  }

  if (isImageMessage(message)) {
    return <MaestroGameMessageImage message={message} />;
  }

  if (isStatBlockMessage(message)) {
    return <MaestroGameMessageStatBlock message={message} />;
  }

  if (isEndEpisodeMessage(message)) {
    return <MaestroGameMessageEndEpisode game={game} message={message} />;
  }

  if (isPlayerInputMessage(message)) {
    return (
      <MaestroGameMessagePlayerInput
        game={game}
        message={message}
        isLastMessage={isLastMessage}
      />
    );
  }

  if (isPlayerInputResultMessage(message)) {
    return <MaestroGameMessagePlayerInputResult message={message} />;
  }

  if (isObjectiveCompletedMessage(message)) {
    return <MaestroGameMessageObjectiveCompleted message={message} />;
  }

  if (isInventoryItemChangeMessage(message)) {
    return <MaestroGameMessageInventoryItemChange message={message} />;
  }

  if (isDiceRollInteractionMessage(message)) {
    return (
      <MaestroGameMessageDiceRollInteraction
        game={game}
        message={message}
        isLastMessage={isLastMessage}
      />
    );
  }

  if (isDiceRollResultMessage(message)) {
    return <MaestroGameMessageDiceRollResult message={message} />;
  }

  if (isPlayerAttributeMessage(message)) {
    return <MaestroGameMessagePlayerAttribute message={message} />;
  }

  if (isCoinTossInteractionMessage(message)) {
    return (
      <MaestroGameMessageCoinTossInteraction
        game={game}
        message={message}
        isLastMessage={isLastMessage}
      />
    );
  }

  if (isCoinTossResultMessage(message)) {
    return <MaestroGameMessageCoinTossResult message={message} />;
  }

  return <UnknownMessage>Unknown message</UnknownMessage>;
};

export const MaestroGameMessage = memo(Component);

const UnknownMessage = styled.div`
  ${textStyles.body.b16sbi};
  margin: ${dimensions.size32} ${dimensions.size0} ${dimensions.size64}
    ${dimensions.size0};
  color: ${({ theme }) => theme.colors.text.body};
  animation: ${messageAnimation} 0.5s ease-in-out forwards;
`;
