import { useDisclosure } from '@chakra-ui/react';
import { TextInput } from '@maestro/components';
import {
  Menu,
  MenuDropdownButton,
  MenuItem,
  MenuList,
  MenuGroup,
  IconMenuItem,
} from '@maestro/components/menu/Menu';
import { dimensions, textStyles } from '@maestro/styles';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Item } from '../../hooks/useItems';
import { useStudioFlowStore } from '../../hooks/useStudioFlowStore';
import { CreateItemModal } from '../items/CreateItemModal';

type Props = {
  value: string;
  items: Item[];
  onItemCreated: (item: Item) => void;
  onChange: (item: Item) => void;
};

export const ItemInput: React.FC<Props> = (props) => {
  const { onChange, items, value, onItemCreated } = props;
  const { seriesId } = useStudioFlowStore();
  const item = items.find((item) => item.id === value);
  const [search, setSearch] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const filteredItems = useMemo(() => {
    if (!search) return items;

    return items.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, items]);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onClear = (e: React.MouseEvent) => {
    setSearch('');
    e.stopPropagation();
  };

  const onAddItem = () => {
    onOpen();
  };

  const onCreateItem = (item: Item) => {
    onItemCreated(item);
    onChange(item);
    onClose();
  };

  return (
    <>
      {seriesId && (
        <CreateItemModal
          seriesId={seriesId}
          isOpen={isOpen}
          onClose={onClose}
          onCreated={onCreateItem}
        />
      )}
      <StyledMenu>
        <MenuDropdownButton>
          {item && (
            <IconContainer>
              <img src={item.imagePath} />
              {item.name}
            </IconContainer>
          )}
        </MenuDropdownButton>
        <MenuList>
          <Searcher>
            <TextInput
              value={search}
              showRemoveButton
              onRemove={onClear}
              placeholder="Search"
              onClick={(e) => e.stopPropagation()}
              onChange={onChangeSearch}
            />
          </Searcher>

          <IconMenuItem icon="plus" name="Create item" onClick={onAddItem} />

          <MenuGroup>Series items</MenuGroup>
          {filteredItems.map((menuItem) => (
            <MenuItem key={menuItem.id} onClick={() => onChange(menuItem)}>
              <IconContainer>
                <img src={menuItem.imagePath} />
                {menuItem.name}
              </IconContainer>
            </MenuItem>
          ))}
        </MenuList>
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)`
  flex: 1;
`;

const Searcher = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  padding: 0 ${dimensions.size8};
  margin-bottom: ${dimensions.size8};
`;

const IconContainer = styled.div`
  ${textStyles.body.b12m}
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  border-radius: ${dimensions.size4};

  img {
    width: ${dimensions.size32};
    height: ${dimensions.size32};
    object-fit: cover;
    border-radius: ${dimensions.size4};
  }
`;
