import type { EpisodeData } from './episodeData';
import type { GameConfig } from './gameConfig';
import type { GameObjective, GameObjectiveMetadata } from './gameObjective';
import type { Message } from './messages.types';
import type { PlayerData } from './player';

export type GameData = {
  state: GameState;
  playerData: PlayerData;
};

export enum CoinTossChoice {
  Heads = 'heads',
  Tails = 'tails',
  ConfirmFail = 'confirm-fail',
}

export enum CoinTossSide {
  Heads = 'heads',
  Tails = 'tails',
}

export type GameState = {
  input: Record<string, string | number>;
  currentNodeId: string | undefined;
  currentInteraction: string | undefined;
  coinTossData: { choice: CoinTossSide; failed: true } | undefined;
  objectives: Record<string, GameObjectiveMetadata>;
  properties: Record<string, number | string>;
  selectedOptions?: Record<string, string[]>;
};

export type Game = {
  config: GameConfig;
  init(data?: GameState): void;
  onCharacterCreated(): Promise<Message[]>;
  continueEpisode(): Promise<Message[]>;
  playerInput(id: string, input: string): Promise<Message[]>;
  singleSelect(id: string, optionId: string): Promise<Message[]>;
  coinToss(id: string, choice: CoinTossChoice): Promise<Message[]>;
  rollDices(id: string): Promise<Message[]>;
  triggerItemAction(itemId: string): Promise<Message[]>;
  startGame(startNodeId?: string): Promise<Message[]>;
  objectives(): Array<GameObjective & { state: GameObjectiveMetadata }>;
  episodeData(): Promise<EpisodeData>;
  serialize(): string;
  setState(state: GameState): void;
  getState(): GameState;
};
