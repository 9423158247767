import {
  RpgConfig,
  StudioFlowState,
  StudioNodeData,
} from '@common/studio-types';
import { migrateRpgConfig } from '@common/studio-types/migration';
import { useStudioEpisodeQuery } from '@maestro/graphql';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Edge, Node, ReactFlowInstance } from 'reactflow';
import { LocalFlowState, MaestroStudioFlowState } from '../types/studio-flow';
import { useDebounce } from './useDebounce';
import { useSaveStudioFlow } from './useSaveStudioFlow';
import { SaveStatus, useStudioFlowStore } from './useStudioFlowStore';
import { cleanFlowState, defaultStartNode } from './utils';

/**
 * Saves and fetches the current state of the Studio Flow to disk
 */
export const useStudioFlow = () => {
  const { id } = useParams();
  const [draftEpisodeVersionId, setDraftEpisodeVersionId] = useState<
    string | null
  >(null);
  const [flowState, setFlowState] = useState<MaestroStudioFlowState>();
  const [isPublished, setIsPublished] = useState(false);
  const [hasDraft, setHasDraft] = useState(false);
  const {
    seriesId,
    setSeriesId,
    setSeriesTitle,
    setEpisodeName,
    setEpisodeRef,
    setEpisodeCoverUrl,
    setSaveStatus,
    setRpgConfig,
  } = useStudioFlowStore();

  const { data: studioEpisodeData, isRefetching } = useStudioEpisodeQuery(
    { input: { episodeId: id! } },
    { cacheTime: 0, staleTime: 0, retry: false, initialData: undefined },
  );

  const { saveStudioFlow } = useSaveStudioFlow({
    id,
    draftEpisodeVersionId,
  });

  useEffect(() => {
    /* ignore when refetching,
     * because we don't want to render the state twice */
    if (isRefetching) return;

    const loadFlowState = async () => {
      if (!studioEpisodeData?.studioEpisode.data) return;

      const { publishedVersion, draftVersion, ...episode } =
        studioEpisodeData.studioEpisode.data;

      // eslint-disable-next-line no-console
      console.info('Draft Studio Flow State: ', draftVersion);

      if (!!draftVersion && !!episode) {
        const { series } = episode;

        setDraftEpisodeVersionId(draftVersion.id);
        setEpisodeName(episode.title);
        setSeriesId(series.id);
        setSeriesTitle(series.title);
        setIsPublished(!!publishedVersion?.publishedAt);

        if (!!episode.ref) {
          setEpisodeRef(episode.ref);
        }

        setEpisodeCoverUrl(episode.media.main);
        setHasDraft(
          !!draftVersion?.updatedAt && !!publishedVersion?.publishedAt,
        );

        const rpgConfig = migrateRpgConfig(series.rpgConfig as RpgConfig);
        setRpgConfig(rpgConfig);

        const state = draftVersion.studioFlow as MaestroStudioFlowState;

        if (!!state && Object.keys(state).length > 0) {
          const local = localStorage.getItem(`studio_flow:${id}`);

          if (local) {
            const localObject = JSON.parse(local) as LocalFlowState;
            const localDate = new Date(localObject.updatedAt);
            const serverDateString =
              draftVersion.updatedAt ?? draftVersion.createdAt;
            const serverDate = new Date(`${serverDateString}Z`);

            // only restore local session if it's newer than remote version
            if (localDate.getTime() > serverDate.getTime()) {
              setFlowState(cleanFlowState(localObject.flowState, rpgConfig));
            } else {
              setFlowState(cleanFlowState(state, rpgConfig));
            }
          } else {
            setFlowState(cleanFlowState(state, rpgConfig));
          }
        } else {
          setFlowState({
            version: 2,
            edges: [],
            nodes: [
              {
                ...(defaultStartNode as StudioFlowState<
                  Node<StudioNodeData>,
                  Edge
                >['nodes'][0]),
                data: { ...defaultStartNode.data, newEpisode: true },
              },
            ],
          });
        }
      }
    };

    if (!!studioEpisodeData?.studioEpisode.data) {
      loadFlowState().catch(() => void 0);
    }
  }, [studioEpisodeData, setFlowState]);

  const delayedSave = useDebounce(
    async (flowState: StudioFlowState<Node<StudioNodeData>, Edge>) => {
      setSaveStatus('saving');
      const newStatus = await saveStudioFlow(flowState).catch(
        () => 'offline' as SaveStatus,
      );
      setSaveStatus(newStatus);
    },
    [saveStudioFlow],
    3000,
  );

  const save = useCallback(
    async (instance: ReactFlowInstance) => {
      const flowState = instance.toObject();

      if (flowState.edges.length === 0 && flowState.nodes.length === 0) {
        // we should not save an empty flow
        return;
      }

      const state: MaestroStudioFlowState = {
        /**
         * hard coding to 1 in Maestro,
         * so if this instance isn't updated (aka user on an old tab),
         * we will know this is version 1
         */
        version: 2,
        edges: flowState.edges as MaestroStudioFlowState['edges'],
        nodes: flowState.nodes as MaestroStudioFlowState['nodes'],
      };

      setFlowState(state);

      return delayedSave(state);
    },
    [delayedSave],
  );

  return {
    episodeId: id,
    seriesId,
    flowState,
    save,
    isPublished,
    hasDraft,
  };
};
