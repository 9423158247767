import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import {
  StudioFlowNode,
  StudioNodeData,
  StudioNodeType,
} from '@common/studio-types';
import { Icon, Loader, Modal, ModalBody, TextInput } from '@maestro/components';
import { env } from '@maestro/env';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { useState } from 'react';
import { useReactFlow } from 'reactflow';
import styled from 'styled-components';
import { useShortcut } from '../hooks/useShortcut';
import { NodeDiff } from './NodeDiff';

type Props = {
  onBeforeChange: VoidFunction;
  onChange: VoidFunction;
};

export const StudioAiAssistant: React.FC<Props> = (props) => {
  const { onBeforeChange, onChange } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [changes, setChanges] = useState<
    {
      id: string;
      old: StudioNodeData;
      updated: StudioNodeData;
      selected: boolean;
    }[]
  >([]);
  const isFeatureEnabled = useFeatureFlag(FeatureFlags.AskAI);
  const onClear = () => {
    setPrompt('');
    setChanges([]);
  };

  useShortcut('Control+Space', () => {
    if (isFeatureEnabled) {
      onOpen();
      onClear();
    }
  });

  const request = async (
    prompt: string,
    selectedNodes: Pick<StudioFlowNode, 'id' | 'data'>[],
  ): Promise<StudioNodeData[]> => {
    const body = JSON.stringify({ prompt, selectedNodes });

    const response = await fetch(`${env.VITE_MAESTRO_API_URL}/studio/ask-ai`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });

    return response.json();
  };
  const { getNodes, setNodes } = useReactFlow();
  const onEnter = async () => {
    setIsLoading(true);
    setChanges([]);

    const allNodes = getNodes();
    const selectedNodes = allNodes.filter((node) => node.selected);
    const relevantNodes = selectedNodes
      .filter((node) => {
        return (
          node.type === StudioNodeType.NarratorText ||
          node.type === StudioNodeType.SingleSelect
        );
      })
      .map(({ id, data }) => ({ id, data }));

    const result = await request(prompt, relevantNodes).finally(() =>
      setIsLoading(false),
    );
    const map: Record<string, StudioNodeData> = result.reduce(
      (acc, nodeData) => ({ ...acc, [nodeData.id]: nodeData }),
      {} as Record<string, StudioNodeData>,
    );

    setChanges(
      relevantNodes
        .map(({ id, data }) => ({
          id,
          old: data,
          updated: map[id],
          selected: true,
        }))
        .filter(
          ({ updated, old }) =>
            !!updated && JSON.stringify(old) !== JSON.stringify(updated),
        ),
    );
  };

  const applySelected = () => {
    onBeforeChange();

    const nodes = getNodes();
    const map: Record<string, StudioNodeData> = changes
      .filter((change) => change.selected)
      .reduce(
        (acc, change) => ({ ...acc, [change.id]: change.updated }),
        {} as Record<string, StudioNodeData>,
      );

    setNodes(
      nodes.map((node) =>
        map[node.id] ? { ...node, data: map[node.id] } : node,
      ),
    );
    onClose();
    onChange();
  };

  const onSelectionChange = (id: string, selected: boolean) => {
    setChanges(
      changes.map((change) =>
        change.id === id ? { ...change, selected } : change,
      ),
    );
  };

  return (
    <StyledModal isOpen={isOpen} onClose={onClose} title="Ask AI">
      <ModalBody>
        <Container>
          <TextInput
            isDisabled={isLoading}
            autoFocus
            leftIcon={
              <Icon
                name="ai-generate"
                color="gray"
                size={rawDimensions.size24}
              />
            }
            showRemoveButton
            onRemove={() => onClear()}
            size="lg"
            placeholder="Change narration tone to kids"
            value={prompt}
            onChange={(evt) => setPrompt(evt.target.value)}
            onKeyUp={(evt) => evt.key === 'Enter' && onEnter()}
          />
          {isLoading && <Loader label="Making magic with AI" />}
          {changes.length > 0 && (
            <NodeDiffsContainer>
              <ButtonGroup>
                <Button onClick={applySelected} variant="default">
                  Apply to selected
                </Button>
              </ButtonGroup>
              {changes.map((props) => (
                <NodeDiff
                  {...props}
                  onSelectionChange={(selected: boolean) =>
                    onSelectionChange(props.id, selected)
                  }
                  key={props.id}
                />
              ))}
            </NodeDiffsContainer>
          )}
        </Container>
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  min-height: 0;
`;

const Container = styled.div`
  padding: ${dimensions.size16};
`;

const NodeDiffsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  margin-top: ${dimensions.size16};
`;
