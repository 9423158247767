import { StudioNodeType } from '@common/studio-types';
import {
  changeValue,
  characterCreatorComplete,
  coinToss,
  completeObjective,
  conditionCheck,
  createCharacter,
  diceRoll,
  endEpisode,
  getValueAction,
  image,
  locationUpdate,
  onboardingComplete,
  singleSelectInteraction,
  statBlock,
  text,
  playerInput,
} from '../actions';
import type { ActionResult } from '../actions/actions.types';
import type { GameData, GameState } from '../game';
import type { Message } from '../game/messages.types';
import type { GameNode } from '../studioGameCreator.types';
import { GameNodes, isCharacterCreationNode } from './getGameNodes';
import type { StatefulGameConfig } from './statefulGame';

export const executeNode = async (
  node: GameNode,
  data: GameData,
  config: StatefulGameConfig,
  gameNodes: GameNodes,
): Promise<ActionResult> => {
  const { state } = data;

  switch (node.type) {
    case StudioNodeType.NarratorText:
      return text(node, state, data.playerData, config);
    case StudioNodeType.Image:
      return image(node, state);
    case StudioNodeType.GetValue:
      return getValueAction(node, state, config, data.playerData);
    case StudioNodeType.ChangeValue:
      return changeValue(node, state, data.playerData, config);
    case StudioNodeType.ConditionCheck:
      return conditionCheck(node, state, config, data.playerData);
    case StudioNodeType.DiceRoll:
      return diceRoll(node, state, config, data.playerData);
    case StudioNodeType.CoinToss:
      return coinToss(node, state, data.playerData, config);
    case StudioNodeType.CharacterCreation:
      return createCharacter(state);
    case StudioNodeType.SingleSelect:
      return singleSelectInteraction(
        node,
        state,
        config,
        gameNodes,
        data.playerData,
      );
    case StudioNodeType.ObjectiveComplete:
      return completeObjective(node, state);
    case StudioNodeType.EndEpisode:
      return endEpisode(node, state);
    case StudioNodeType.LocationUpdate:
      return locationUpdate(node, state);
    case StudioNodeType.StatBlock:
      return statBlock(node, state, data.playerData, config);
    case StudioNodeType.OnboardingComplete:
      return onboardingComplete(node, state);
    case StudioNodeType.PlayerInput:
      return playerInput(node, state, config, data.playerData);
    case StudioNodeType.CharacterCreatorComplete:
      return characterCreatorComplete(node, state);

    // ignored nodes
    case StudioNodeType.EpisodeSetup:
    case StudioNodeType.Comment:
    case StudioNodeType.Start:
    default:
      return { messages: [], state };
  }
};

export const executeNodes = async (
  gameNodes: GameNodes,
  nextNodeId: string,
  data: GameData,
  config: StatefulGameConfig,
): Promise<ActionResult> => {
  let node = gameNodes.nodeById(nextNodeId);
  let state: GameState = { ...data.state };
  const messages: Message[] = [];

  while (node) {
    const shouldCreateCharacter = !data.playerData.isCreated;
    const shouldExecuteNode =
      !isCharacterCreationNode(node) || shouldCreateCharacter;
    let nextNodeId: string | undefined = node.nextNodeId;

    if (shouldExecuteNode) {
      const result = await executeNode(
        node,
        { ...data, state },
        config,
        gameNodes,
      );

      messages.push(...result.messages);

      state = {
        ...result.state,
        currentNodeId: node.id,
        input: {
          ...(result.state.input ?? {}),
          ...(result.output ?? {}),
        },
      };

      if (result.haltExecution) {
        break;
      } else if (result.nextNodeId) {
        nextNodeId = result.nextNodeId;
      }
    }

    node = nextNodeId ? gameNodes.nodeById(nextNodeId) : undefined;
  }

  return { messages, state };
};
