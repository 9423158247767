import { FC, useRef, useMemo } from 'react';
import { GenerateWithAiButton } from '../../../components/GenerateWithAiButton';
import {
  CREATE_WORLD_STEPS,
  CreateWorldFields,
  GenerateNPCResponse,
  GeneratedLocationResponse,
  WorldCharacter,
  WorldLocation,
} from '../types';
import { GenerateWorldBibleWithAiForm } from './AiHelperForms/GenerateWorldBibleWithAiForm';
import { GenerateWorldItemWithAiForm } from './AiHelperForms/GenerateWorldItemWithAiForm';

type Props = {
  step: CREATE_WORLD_STEPS;
  onChange: (world: CreateWorldFields) => void;
  world: CreateWorldFields;
  setCharacters: (characters: WorldCharacter[]) => void;
  setLocations: (locations: WorldLocation[]) => void;
  characters: WorldCharacter[];
};

export const CreateWorldAiHelper: FC<Props> = ({
  onChange,
  step,
  world,
  setCharacters,
  setLocations,
  characters,
}) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const modalProps = useMemo(() => {
    if (step === CREATE_WORLD_STEPS.BASE_FIELDS) {
      return {
        submitButtonRef,
        title: 'Generate World Bible',
        children: (
          <GenerateWorldBibleWithAiForm
            world={world}
            submitButtonRef={submitButtonRef}
            onChange={onChange}
          />
        ),
      };
    }

    if (step === CREATE_WORLD_STEPS.NPCS && !!world.story) {
      return {
        submitButtonRef,
        title: 'How many characters do you want to create?',
        children: (
          <GenerateWorldItemWithAiForm
            submitButtonRef={submitButtonRef}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
            setWorldItems={(items: Record<string, unknown>) =>
              setCharacters(
                (items as GenerateNPCResponse)?.characters?.map(
                  (character) =>
                    ({
                      name: character.name,
                      traits: `${character.physicalDescription} ${character.fears} ${character.personality}`,
                      goals: `${character.goals} ${character.motivations}`,
                      relationships: character.relationships,
                      generatedOutput: character,
                    }) as WorldCharacter,
                ),
              )
            }
            itemType="character"
            story={world.story}
            worldContext={{ characters }}
          />
        ),
      };
    }

    if (step === CREATE_WORLD_STEPS.LOCATIONS && !!world.story) {
      return {
        submitButtonRef,
        title: 'How many locations do you want to add?',
        children: (
          <GenerateWorldItemWithAiForm
            submitButtonRef={submitButtonRef}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
            setWorldItems={(items: Record<string, unknown>) =>
              setLocations(
                (items as GeneratedLocationResponse)?.locations?.map(
                  (location) =>
                    ({
                      name: location.name,
                      description: location.description,
                      npcs: location.npcs,
                      generatedOutput: location,
                    }) as WorldLocation,
                ),
              )
            }
            itemType="location"
            story={world.story}
            worldContext={{ characters }}
          />
        ),
      };
    }

    return null;
  }, [step, world.story]);

  if (!modalProps) {
    return null;
  }

  return <GenerateWithAiButton {...modalProps}></GenerateWithAiButton>;
};
