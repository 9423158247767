import { Button } from '@chakra-ui/react';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';

type Props = {
  showChevronButton?: boolean;
  onClose: () => void;
  title: string;
  borderDisabled?: boolean;
};

export const ModalHeader: React.FC<Props> = (props) => {
  const { onClose, title, showChevronButton, borderDisabled } = props;

  return (
    <Container $borderDisabled={borderDisabled}>
      <FlexContainer>
        {showChevronButton && (
          <Button variant="drawerButton" onClick={onClose}>
            <Icon name="chevron-right" size={rawDimensions.size20} />
          </Button>
        )}
      </FlexContainer>
      <TitleContainer>{title}</TitleContainer>
      <CloseContainer>
        <Button variant="drawerButton" onClick={onClose}>
          <Icon name="close" size={rawDimensions.size20} />
        </Button>
      </CloseContainer>
    </Container>
  );
};

const Container = styled.div<{ $borderDisabled?: boolean }>`
  ${textStyles.label.lb14sb}
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${dimensions.size8} ${dimensions.size8} ${dimensions.size0}
    ${dimensions.size0};
  padding: ${dimensions.size8} ${dimensions.size16};
  background-color: ${({ theme }) => theme.colors.background.default};
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: ${({ $borderDisabled }) =>
      $borderDisabled ? dimensions.size0 : dimensions.size1}
    solid ${({ theme }) => theme.colors.border.default[100]};
`;

const FlexContainer = styled.div`
  flex: 1;
`;

const CloseContainer = styled(FlexContainer)`
  display: flex;
  justify-content: end;
`;

const TitleContainer = styled(FlexContainer)`
  text-align: center;
`;
