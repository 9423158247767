import type { StudioFlowState } from '@common/studio-types';
import { migrateRpgConfig } from '@common/studio-types/migration';
import type { Game } from '../game';
import type { GameConfig } from '../game/gameConfig';
import type { PlayerData } from '../game/player';
import { getEpisodeData } from './getEpisodeData';
import { getGameNodes } from './getGameNodes';
import { handleCoinTossInteraction } from './handleCoinTossInteraction';
import { handleContinueEpisode } from './handleContinueEpisode';
import { handleDiceRollInteraction } from './handleDiceRollInteraction';
import { handlePlayerInput } from './handlePlayerInput';
import { handleSingleSelectInteraction } from './handleSingleSelectInteraction';
import { handleTriggerItemActionInteraction } from './handleTriggerItemActionInteraction';
import { init } from './init';
import { onCharacterCreated } from './onCharacterCreated';
import { startGame } from './startGame';
import { statefulGame } from './statefulGame';

/**
 * There are still a few things we need to do on this file:
 * 1. Validation of assumptions
 * 2. Unit Tests
 */

type CreateGameArgs = {
  config: GameConfig;
  studioFlowState: StudioFlowState;
  playerData: PlayerData;
};

export const createGame = (args: CreateGameArgs): Game => {
  const { studioFlowState, playerData, config } = args;
  const gameNodes = getGameNodes(studioFlowState, config);

  config.rpgConfig = migrateRpgConfig(config.rpgConfig);

  const game = statefulGame(
    gameNodes,
    { playerData, state: null as never },
    config,
    args.playerData,
  );

  const episodeData = () => getEpisodeData(game, playerData, game.config);

  return {
    continueEpisode: () => handleContinueEpisode(game),
    init: (state) => init(game, state),
    coinToss: (interactionId, choice) =>
      handleCoinTossInteraction(game, { interactionId, choice }),
    rollDices: (interactionId) =>
      handleDiceRollInteraction(game, { interactionId }),
    playerInput: (interactionId, playerInput) =>
      handlePlayerInput(game, { interactionId, playerInput }),
    singleSelect: (interactionId, optionId) =>
      handleSingleSelectInteraction(game, { interactionId, optionId }),
    triggerItemAction: async (itemId) => {
      return handleTriggerItemActionInteraction(game, {
        itemId,
        episodeData: await episodeData(),
      });
    },
    objectives: () =>
      gameNodes.objectiveCompleteNodes().map((node) => ({
        ref: node.id,
        name: node.objective,
        silent: false,
        hidden: true,
        state: game.state().objectives[node.id],
      })),
    episodeData,
    onCharacterCreated: () => onCharacterCreated(game),
    startGame: (nodeId) => startGame(game, nodeId),
    setState: (state) => game.setGameState(state),
    getState: () => game.state(),
    serialize: () => JSON.stringify(game.state()),
    config,
  };
};
