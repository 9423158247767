import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Children,
  PropsWithChildren,
  cloneElement,
  isValidElement,
  useState,
} from 'react';

type Props = PropsWithChildren<{
  title: string;
  buttonLabel?: string;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
}>;

export const GenerateWithAiButton = ({
  title,
  children,
  submitButtonRef,
  buttonLabel = 'Generate with AI',
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPrimaryClick = () => {
    submitButtonRef.current?.click();
  };

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        onClose,
        setLoadingState: (loading: boolean) => setIsLoading(loading),
      } as {
        onClose: () => void;
        setLoadingState: (loading: boolean) => void;
      });
    }

    return child;
  });

  return (
    <>
      <Button variant="primary" onClick={onOpen}>
        {buttonLabel}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={!isLoading}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton disabled={isLoading} />
          <ModalBody>{childrenWithProps}</ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={onClose}
              disabled={isLoading}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={onPrimaryClick}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
