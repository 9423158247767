import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { Icon, TextInput, Textarea } from '@maestro/components';
import { useFeatureFlagEvaluation } from '@maestro/feature-flags';
import { FC, useState } from 'react';
import { useGenerateWorldItems } from '../../hooks/useGenerateWorldItems';
import {
  GeneratedLocationResponse,
  WorldCharacter,
  WorldLocation,
} from '../../types';
import {
  GeneratorFormContainer,
  GeneratorFormHeader,
  GeneratorItemBody,
} from './GeneratorComponents';

type Props = {
  story: string;
  submitPressed?: boolean;
  characters: WorldCharacter[];
  locations: WorldLocation[];
  onDelete: () => void;
  location: WorldLocation;
  onChange: (location: WorldLocation) => void;
  index: number;
  onGeneratingContent: (generating: boolean) => void;
};

export const LocationForm: FC<Props> = ({
  characters,
  story,
  location,
  submitPressed,
  onDelete,
  onChange,
  index,
  onGeneratingContent,
}) => {
  const [isGeneratingLocation, setIsGeneratingLocation] =
    useState<boolean>(false);
  const { generateWorldItems } = useGenerateWorldItems();
  const { getFlagEvaluation } = useFeatureFlagEvaluation();
  const isGenerateWithAiEnabled = getFlagEvaluation('ENABLE-GENERATE-WITH-AI');

  const generateWithAi = async () => {
    onGeneratingContent(true);
    setIsGeneratingLocation(true);
    const { items } = await generateWorldItems({
      count: 1,
      itemType: 'location',
      story,
      worldContext: { characters },
    }).finally(() => {
      onGeneratingContent(false);
      setIsGeneratingLocation(false);
    });

    const location = items
      .locations[0] as GeneratedLocationResponse['locations'][0];

    onChange({
      name: location.name,
      description: location.description,
      npcs: location.npcs,
      generatedOutput: location,
    });
  };

  const canRegenerate = location.name || location.npcs || location.description;

  return (
    <GeneratorFormContainer>
      <GeneratorFormHeader>
        <Text fontWeight={'semibold'} fontSize={18}>
          Location {index + 1}
        </Text>
        <ButtonGroup>
          <Button
            isDisabled={isGeneratingLocation}
            onClick={() => onDelete()}
            variant="ghost"
            size="sm"
            leftIcon={<Icon name="trash" size={16} />}
          >
            Delete
          </Button>
          {isGenerateWithAiEnabled && (
            <Button
              onClick={generateWithAi}
              isLoading={isGeneratingLocation}
              type="submit"
              size="sm"
              variant="ghost"
            >
              {canRegenerate ? 'Regenerate' : 'Generate'} with AI
            </Button>
          )}
        </ButtonGroup>
      </GeneratorFormHeader>
      <GeneratorItemBody>
        <FormControl mb="3" isInvalid={submitPressed && !location.name}>
          <FormLabel>Name of Location</FormLabel>
          <TextInput
            maxCharacters={100}
            value={location.name}
            onChange={(e) => onChange({ ...location, name: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !location.name && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl mb="3" isInvalid={submitPressed && !location.description}>
          <FormLabel>
            Describe the location. Include information like weather, building
            materials, appearance, layout, streets or sidewalks and density of
            people
          </FormLabel>
          <Textarea
            maxCharacters={2000}
            value={location.description}
            onChange={(e) =>
              onChange({ ...location, description: e.target.value })
            }
          />
          <FormErrorMessage>
            {submitPressed && !location.description && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl mb="3" isInvalid={submitPressed && !location.npcs}>
          <FormLabel>
            Name the NPCs who spend time at this location and what they do
            there?
          </FormLabel>
          <Textarea
            maxCharacters={2000}
            value={location.npcs}
            onChange={(e) => onChange({ ...location, npcs: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !location.npcs && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
      </GeneratorItemBody>
    </GeneratorFormContainer>
  );
};
