import { Button } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { rawDimensions } from '@maestro/styles';
import { EmptyState } from '../../../../components/EmptyState';
import { WorldCharacter, WorldLocation } from '../../types';
import { CharacterForm } from './CharacterForm';
import {
  Container,
  GeneratorFormContainer,
  Header,
  TextContainer,
} from './GeneratorComponents';

type Props = {
  submitPressed?: boolean;
  story: string;
  characters: WorldCharacter[];
  locations: WorldLocation[];
  onNewCharacter: () => void;
  onCharacterChange: (characters: WorldCharacter, index: number) => void;
  onCharacterDelete: (index: number) => void;
  onGeneratingContent: (generating: boolean) => void;
};

export const NpcsGenerator: React.FC<Props> = ({
  characters,
  locations,
  story,
  submitPressed,
  onNewCharacter,
  onCharacterChange,
  onCharacterDelete,
  onGeneratingContent,
}) => {
  return (
    <Container>
      <Header>
        <TextContainer></TextContainer>
        <Button
          onClick={() => onNewCharacter()}
          variant="outline"
          leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
        >
          Character
        </Button>
      </Header>
      {!characters ||
        (characters.length === 0 && (
          <GeneratorFormContainer>
            <EmptyState
              title="No characters, add your first character"
              button={{ label: 'Add Character', onClick: onNewCharacter }}
            />
          </GeneratorFormContainer>
        ))}
      {characters?.map((character, index) => (
        <CharacterForm
          submitPressed={submitPressed}
          key={`character-${index}`}
          character={character}
          story={story}
          characters={characters}
          locations={locations}
          onDelete={() => onCharacterDelete(index)}
          onChange={(nextCharacter) => onCharacterChange(nextCharacter, index)}
          index={index}
          onGeneratingContent={onGeneratingContent}
        />
      ))}
    </Container>
  );
};
