import {
  SeriesShortFragment,
  useUpdateDiscoverySectionSeriesMutation,
} from '@maestro/graphql';
import { SectionSeries } from '../sections.type';

export const useSaveSection = () => {
  const { mutate, isLoading: isSaving } =
    useUpdateDiscoverySectionSeriesMutation();

  const saveSection = async (
    section: SectionSeries,
    series?: SeriesShortFragment[],
  ) => {
    mutate({
      input: {
        id: section.id,
        name: section.name,
        series: series?.map((s) => s.id),
      },
    });
  };

  return { saveSection, isSaving };
};
