import { Button } from '@chakra-ui/react';
import { textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  button?: {
    label: string;
    onClick: () => void;
  };
  buttonComponent?: React.ReactNode;
};

export const EmptyState: React.FC<Props> = (props) => (
  <Container>
    <Title>{props.title}</Title>
    {props.buttonComponent}
    {props.button && (
      <Button variant="default" size="sm" onClick={props.button.onClick}>
        {props.button.label}
      </Button>
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 32px;
  text-align: center;
`;

const Title = styled.div`
  ${textStyles.body.b18sb}
  color: ${({ theme }) => theme.colors.text.body};
`;
