import {
  StudioNodeType,
  type SingleSelectNodeData,
} from '@common/studio-types';
import type { Message } from '../game/messages.types';
import { toSingleSelectOption } from './interactionUtils';
import { StatefulGame } from './statefulGame';

type Args = {
  interactionId: string;
  optionId: string;
};

export const handleSingleSelectInteraction = async (
  game: StatefulGame,
  interactionArgs: Args,
): Promise<Message[]> => {
  const { interactionId, optionId } = interactionArgs;
  const interaction =
    game.gameNodes.nodeById<SingleSelectNodeData>(interactionId);

  if (
    !interaction ||
    game.state().currentInteraction !== interactionId ||
    interaction?.type !== StudioNodeType.SingleSelect
  ) {
    return [game.errorMessage('Invalid interaction')];
  }

  const option = interaction.options.find(({ id }) => id === optionId);

  if (!option) {
    return [game.errorMessage('Invalid interaction')];
  }

  /* This needs to run BEFORE the game execution, otherwise
   * it'll override the interaction that will be set during execution
   */
  game.setGameState({ currentInteraction: undefined });

  const selectedOptions = game.state().selectedOptions?.[interactionId] ?? [];

  // We need this to prevent hiding ALL options
  if (selectedOptions.length + 1 < interaction.options.length) {
    game.addSelectedOption(interactionId, optionId);
  }

  const messages = await game.execute(option.nextNodeId);

  if (messages.length === 0) {
    // setting back to the current interaction
    game.setGameState({ currentInteraction: interaction.id });

    return [];
  }

  return [
    {
      type: 'selected-interaction-option',
      selectedOption: toSingleSelectOption(option, game.gameNodes, game.config),
    },
    ...messages,
  ];
};
