import type { CharacterCreatorCompleteNodeData } from '@common/studio-types';
import type { GameState } from '../game';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';

export const characterCreatorComplete = (
  block: GameNode<CharacterCreatorCompleteNodeData>,
  state: GameState,
): ActionResult => {
  return {
    messages: [
      {
        type: 'character-creator-complete',
        message: block.text.trim(),
      },
    ],
    state,
  };
};
