import { dimensions } from '@maestro/styles';
import { Reorder } from 'framer-motion';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

type Props = PropsWithChildren<{
  draggable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  records?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReorder?: (values: any[]) => void;
}>;

export const List: React.FC<Props> = (props) => {
  const { children, draggable, records, onReorder } = props;

  if (draggable && records && onReorder) {
    return (
      <Reorder.Group axis="y" values={records} onReorder={onReorder}>
        <Container>{children}</Container>
      </Reorder.Group>
    );
  }

  return <Container>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${dimensions.size4} ${dimensions.size0};
  margin: ${dimensions.size0} ${dimensions.size16};
`;
