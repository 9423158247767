import { Switch } from '@chakra-ui/react';
import {
  NumberPropertyConfig,
  RpgConfig,
  ValueType,
  valueUtils,
} from '@common/studio-types';
import { Field, NumericInput } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { ValueInput } from '../value/ValueInputV2';

type Props = {
  propertyId: string;
  onChange: (data: NumberPropertyConfig) => void;
  data: NumberPropertyConfig;
  rpgConfig: RpgConfig;
};

export const NumberPropertyForm: React.FC<Props> = (props) => {
  const { propertyId, onChange, data, rpgConfig } = props;

  const rpgConfigWithoutThisProp = {
    ...rpgConfig,
    properties: rpgConfig.properties.filter((prop) => prop.id !== propertyId),
  };

  const onMinValueEnabledChange = (isEnabled: boolean) => {
    const value = isEnabled ? valueUtils.staticNumber(0) : undefined;
    onChange({ ...data, minValue: value });
  };

  const onMaxValueEnabledChange = (isEnabled: boolean) => {
    const value = isEnabled ? valueUtils.staticNumber(100) : undefined;
    onChange({ ...data, maxValue: value });
  };

  return (
    <Container>
      <StyledField label="Default Value">
        <NumericInput
          min={-1000}
          placeholder="Default"
          onChange={(defaultValue) => onChange({ ...data, defaultValue })}
          value={data.defaultValue}
        />
      </StyledField>

      <StyledField
        label="Minimum Value"
        rightButton={
          <Switch
            size="lg"
            isChecked={!!data.minValue}
            onChange={(e) => onMinValueEnabledChange(e.target.checked)}
          />
        }
      >
        {data.minValue && (
          <ValueInput
            disabledTypes={[ValueType.Input]}
            rpgConfig={rpgConfigWithoutThisProp}
            value={data.minValue}
            onChange={(minValue) => onChange({ ...data, minValue })}
          />
        )}
      </StyledField>

      <StyledField
        label="Maximum Value"
        rightButton={
          <Switch
            size="lg"
            isChecked={!!data.maxValue}
            onChange={(e) => onMaxValueEnabledChange(e.target.checked)}
          />
        }
      >
        {data.maxValue && (
          <ValueInput
            disabledTypes={[ValueType.Input]}
            rpgConfig={rpgConfigWithoutThisProp}
            value={data.maxValue}
            onChange={(maxValue) => onChange({ ...data, maxValue })}
          />
        )}
      </StyledField>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: center;
  width: 100%;
`;

const StyledField = styled(Field)`
  width: 100%;
`;
