import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import { ObjectiveCompletedMessage } from '@enigma-engine/core';
import { messageAnimation } from './keyframes';

type Props = {
  message: ObjectiveCompletedMessage;
};

const Component: React.FC<Props> = ({ message }) => {
  return (
    <>
      <Container>
        <ObjectiveLabelContainer>
          <Icon name="objective" size={rawDimensions.size16} />
          <ObjectiveLabelText>Objective Complete</ObjectiveLabelText>
        </ObjectiveLabelContainer>

        <ObjectiveDescriptionContainer>
          {message.objective.name}
        </ObjectiveDescriptionContainer>
      </Container>
    </>
  );
};

export const MaestroGameMessageObjectiveCompleted = memo(Component);

const Container = styled.div`
  ${textStyles.body.b14m};
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  white-space: pre-line;
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
  animation: ${messageAnimation} 0.5s ease-in-out forwards;
`;

const ObjectiveLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
`;

const ObjectiveLabelText = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const ObjectiveDescriptionContainer = styled.div`
  ${textStyles.body.b16m}
  padding: ${dimensions.size16};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;
