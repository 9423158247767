import { Button } from '@chakra-ui/react';
import { EnumOption, EnumPropertyConfig } from '@common/studio-types';
import { Field, Icon, TextInput } from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { SmallHint } from '../Hint';

type Props = {
  onChange: (data: EnumPropertyConfig) => void;
  data: EnumPropertyConfig;
};

export const EnumPropertyForm: React.FC<Props> = (props) => {
  const { data, onChange } = props;

  const onAddNewValue = () => {
    onChange({
      ...data,
      options: [
        ...data.options,
        { ref: window.crypto.randomUUID(), label: '', description: '' },
      ],
    });
  };

  const onChangeOption = (ref: string, option: EnumOption) => {
    onChange({
      ...data,
      options: data.options.map((value) =>
        value.ref === ref ? option : value,
      ),
    });
  };

  const onDeleteOption = (ref: string) => {
    onChange({
      ...data,
      options: data.options.filter(({ ref: optionRef }) => optionRef !== ref),
    });
  };

  return (
    <Container>
      <StyledField label="Choices">
        <SmallHint>
          E.g. You can create a choice property called “Race” and enter in
          choices like human, elf, or orc.
        </SmallHint>
        <Columns>
          {data.options.map((option) => (
            <TextInput
              key={option.ref}
              rightElement={
                <Button
                  variant="inputButton"
                  onClick={() => onDeleteOption(option.ref)}
                >
                  <Icon name="trash" size={rawDimensions.size16} />
                </Button>
              }
              placeholder="Name"
              onChange={(e) =>
                onChangeOption(option.ref, { ...option, label: e.target.value })
              }
              value={option.label}
            />
          ))}
          <div>
            <Button
              size="sm"
              variant="default"
              onClick={onAddNewValue}
              isDisabled={data.options.length >= 10}
              leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
            >
              Add choice
            </Button>
          </div>
        </Columns>
      </StyledField>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  align-items: center;
  width: 100%;
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;
