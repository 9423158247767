import { dimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

type ListItemColumnProps = PropsWithChildren<{
  reverse?: boolean;
  draggable?: boolean;
  width?: string;
  flex?: boolean;
}>;

export const ListItemColumn: React.FC<ListItemColumnProps> = ({
  children,
  reverse,
  width,
  flex,
}) => {
  return (
    <ListItemColumnContainer $flex={flex} $width={width}>
      <RowContainer $reverse={reverse}>{children}</RowContainer>
    </ListItemColumnContainer>
  );
};

const ListItemColumnContainer = styled.div<{
  $width?: string;
  $flex?: boolean;
}>`
  ${textStyles.body.b12m}
  width: ${({ $width }) => $width};
  flex: ${({ $flex }) => ($flex ? 1 : 'unset')};
  vertical-align: middle;
  padding: ${dimensions.size0} ${dimensions.size16};
`;

const RowContainer = styled.div<{ $reverse?: boolean }>`
  display: flex;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
  align-items: center;
  gap: ${dimensions.size8};
`;
