import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { ImageInput, TextInput, Textarea } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import { useForm } from '@refinedev/react-hook-form';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Screen } from '../../components/Screen';
import { LocationGenerator } from './components/CreateWorld/LocationGenerator';
import { NpcsGenerator } from './components/CreateWorld/NpcsGenerator';
import { useGeneratedWorldItemsState } from './hooks/useGeneratedWorldItemsState';

export const WorldEdit: React.FC = () => {
  const [image, setImage] = useState<string>();
  const { characters, setCharacters, locations, setLocations } =
    useGeneratedWorldItemsState();
  const [isGeneratingContent, setIsGeneratingContent] =
    useState<boolean>(false);

  const {
    refineCore: { formLoading, onFinish, queryResult },
    saveButtonProps,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = async (values: any) => {
    if (image) {
      await onFinish({
        ...values,
        locations,
        characters,
        media: { main: image, thumbnail: image },
      });

      return;
    }

    await onFinish({
      ...values,
      locations,
      characters,
    });
  };

  useEffect(() => {
    setLocations(queryResult?.data?.data?.locations);
  }, [queryResult?.data?.data?.locations]);

  useEffect(() => {
    setCharacters(queryResult?.data?.data?.characters);
  }, [queryResult?.data?.data?.characters]);

  useEffect(() => {
    setImage(queryResult?.data?.data?.media?.thumbnail);
  }, [queryResult?.data?.data?.media?.thumbnail]);

  return (
    <Screen
      backPath={`/world/${queryResult?.data?.data?.id}`}
      isLoading={formLoading}
      buttons={
        <ButtonGroup>
          <Button
            {...saveButtonProps}
            isDisabled={isGeneratingContent}
            onClick={handleSubmit(submit)}
            variant="primary"
            size="md"
          >
            Save
          </Button>
        </ButtonGroup>
      }
      title={'Edit World'}
    >
      <FormControl mb="3" isInvalid={!!errors?.title}>
        <FormLabel>Title</FormLabel>
        <TextInput
          id="title"
          type="text"
          {...register('title', { required: 'Title is required' })}
        />
        <FormErrorMessage>{`${errors.title?.message}`}</FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!errors?.source_story}>
        <FormLabel>Story</FormLabel>
        <Textarea
          id="source_story"
          placeholder="Paste your story here. Make sure you include locations, protraits, plot, and any other details vital to create your world bible."
          {...register('source_story', { required: 'A story is required' })}
        />
        <FormErrorMessage>{`${errors.source_story?.message}`}</FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!errors?.cover_image}>
        <FormLabel>World Image</FormLabel>
        <ImageInput
          value={image}
          uploadPath={'world'}
          onChange={(image) => setImage(image?.path)}
        />
        <FormErrorMessage>{`${errors.cover_image?.message}`}</FormErrorMessage>
      </FormControl>

      <Divider />

      <LocationGenerator
        story={getValues('source_story')}
        locations={locations}
        characters={characters}
        onLocationChange={(nextLocation, index) =>
          setLocations((state) => [
            ...state.map((l, i) => (i === index ? nextLocation : l)),
          ])
        }
        onLocationDelete={(index) =>
          setLocations((state) => [...state.filter((_, i) => i !== index)])
        }
        onNewLocation={() =>
          setLocations((state) => [
            ...state,
            {
              name: '',
              description: '',
              npcs: '',
            },
          ])
        }
        onGeneratingContent={(generating: boolean) =>
          setIsGeneratingContent(generating)
        }
      />
      <Divider />
      <NpcsGenerator
        story={getValues('source_story')}
        locations={locations}
        characters={characters}
        onCharacterChange={(nextCharacter, index) =>
          setCharacters((state) => [
            ...state.map((c, i) => (i === index ? nextCharacter : c)),
          ])
        }
        onCharacterDelete={(index) =>
          setCharacters((state) => [...state.filter((_, i) => i !== index)])
        }
        onNewCharacter={() =>
          setCharacters([
            ...characters,
            {
              name: '',
              traits: '',
              goals: '',
              relationships: '',
            },
          ])
        }
        onGeneratingContent={(generating: boolean) =>
          setIsGeneratingContent(generating)
        }
      />
    </Screen>
  );
};

const Divider = styled.div`
  width: calc(100% + ${dimensions.size32});
  height: ${dimensions.size1};
  margin: ${dimensions.size32} 0 ${dimensions.size32} -${dimensions.size16};
  background: ${({ theme }) => theme.colors.border.default[200]};
`;
