import type { ObjectiveCompleteNodeData } from '@common/studio-types';
import type { GameState } from '../game';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';

export const completeObjective = (
  node: GameNode<ObjectiveCompleteNodeData>,
  state: GameState,
): ActionResult => {
  const newObjectives = {
    ...state.objectives,
    [node.id]: {
      ...state.objectives[node.id],
      hasCompleted: true,
    },
  };

  return {
    messages: [
      {
        nodeId: node.id,
        type: 'objective-completed',
        objective: {
          ref: node.id,
          hidden: false,
          silent: false,
          name: node.objective,
        },
      },
      { type: 'episode-progress', progressPercentage: 100 },
    ],
    state: { ...state, objectives: newObjectives },
  };
};
