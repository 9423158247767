import { CmsCard } from '@maestro/components';
import { StudioEpisode } from '@maestro/graphql';
import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { EpisodeStatus } from './EpisodeStatus';

type Props = {
  episode: StudioEpisode;
};

export const EpisodeCard: React.FC<Props> = ({ episode }) => {
  const hasNewVersion = Boolean(
    !!episode.draftVersion.updatedAt &&
      !!episode?.publishedVersion?.publishedAt,
  );
  const isDraft = !episode?.publishedVersion;
  const isPublished = !!episode?.publishedVersion?.publishedAt;
  const publishedAt = episode?.publishedVersion?.publishedAt ?? undefined;

  return (
    <EpisodeCardContainer>
      <EpisodeStatusContainer>
        <EpisodeStatus
          hasNewVersion={hasNewVersion}
          isDraft={isDraft}
          isPublished={isPublished}
          publishedAt={publishedAt}
        />
      </EpisodeStatusContainer>
      <CmsCard
        aspectRatio={0.66}
        description={episode.description}
        title={episode.title}
        image={episode.media.main}
      />
    </EpisodeCardContainer>
  );
};

const EpisodeCardContainer = styled.div`
  position: relative;
`;

const EpisodeStatusContainer = styled.div`
  position: absolute;
  top: ${dimensions.size8};
  left: ${dimensions.size8};
  z-index: 10;
  border-radius: ${dimensions.size4};
`;
