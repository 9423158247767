import { env } from '@maestro/env';
import { useCallback, useRef } from 'react';
import { LocalFlowState, MaestroStudioFlowState } from '../types/studio-flow';
import { SaveStatus } from './useStudioFlowStore';

type Params = {
  version?: string;
  draftEpisodeVersionId: string | null;
  id?: string;
};

export const useSaveStudioFlow = ({ draftEpisodeVersionId, id }: Params) => {
  const savedStudioFlow = useRef<string>();

  const onFailedSave = (flowState: MaestroStudioFlowState) => {
    // save it to local storage
    const local: LocalFlowState = {
      flowState,
      updatedAt: new Date().toISOString(),
    };

    localStorage.setItem(`studio_flow:${id}`, JSON.stringify(local));
  };

  const saveStudioFlow = useCallback(
    async (flowState: MaestroStudioFlowState): Promise<SaveStatus> => {
      if (flowState.edges.length === 0 && flowState.nodes.length === 0) {
        // we should not save an empty flow
        return;
      }

      const body = JSON.stringify({
        episodeVersionId: draftEpisodeVersionId,
        flowState,
      });

      if (body === savedStudioFlow.current) {
        return 'saved';
      } else {
        savedStudioFlow.current = body;
      }

      try {
        // Realtime message
        const response = await fetch(
          `${env.VITE_MAESTRO_API_URL}/studio/${id}/flow`,
          {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body,
          },
        );

        if (!response.ok) {
          throw Error(
            'Failed to save episode flow state through rest endpoint',
          );
        }

        return 'saved';
      } catch (error) {
        onFailedSave(flowState);

        return 'offline';
      }
    },
    [draftEpisodeVersionId, id],
  );

  return {
    saveStudioFlow,
  };
};
