import { StudioNodeType } from '@common/studio-types';
import { Edge, Node } from 'reactflow';
import {
  Suggestions,
  SuggestionsSection,
} from '../../components/suggestions/suggestion.types';
import { nodeConfigs } from '../../nodes';
import { CreateStudioNode } from '../maestro.types';

export const getStorySuggestion = (
  edges: Edge[],
  nodes: Node[],
  selectNode: (nodeId: string) => void,
  createStudioNode: CreateStudioNode,
): SuggestionsSection | undefined => {
  const startNodeConnected = edges.some((edge) => edge.source === 'start');
  const hasEnding = nodes.some(
    (node) =>
      node.data.type === StudioNodeType.ObjectiveComplete ||
      node.data.type === StudioNodeType.EndEpisode,
  );

  const story: Suggestions[] = [
    !startNodeConnected && {
      id: 'start-node',
      title: 'Start node',
      description: 'Connect the start node to another node',
      action: { title: 'Add', onClick: () => selectNode('start') },
    },
    !hasEnding && {
      id: 'ending',
      title: 'Ending',
      description: 'Add an ending node',
      action: {
        title: 'Add',
        onClick: () => {
          createStudioNode(
            nodeConfigs.ObjectiveComplete.createNodeData(),
            { x: 400, y: 400 },
            true,
          );
        },
      },
    },
  ].filter(Boolean) as Suggestions[];

  return story.length
    ? {
        title: 'Story elements',
        suggestions: story,
        type: 'story',
        required: true,
      }
    : undefined;
};
