import { type StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Icon } from '@maestro/components/Icon';
import {
  FeatureFlags,
  useFeatureFlag,
  useFeatureFlagEvaluation,
} from '@maestro/feature-flags';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import { Item, Menu, Submenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import styled from 'styled-components';
import { CreateNodeProps, CreateStudioNode } from '../hooks/maestro.types';
import { nodeConfigList } from '../nodes';
import { NodeConfig } from '../nodes/Node.types';

type Props = {
  episodeRef: string | null;
  onCreateStudioNode: CreateStudioNode;
};

export const CONTEXT_MENU_ID = 'flowContextMenu';

const rootNodes = nodeConfigList.filter((config) => !config.group);
const groupNodes = nodeConfigList
  .filter((config) => config.group)
  .reduce(
    (acc, config: NodeConfig<StudioNodeType, StudioNodeData>) => {
      if (acc[config.group!]) {
        acc[config.group!].push(config);
      } else {
        acc[config.group!] = [config];
      }

      return acc;
    },
    {} as Record<string, NodeConfig<StudioNodeType, StudioNodeData>[]>,
  );

export const ContextMenu: React.FC<Props> = ({
  onCreateStudioNode,
  episodeRef,
}) => {
  const { getFlagEvaluation } = useFeatureFlagEvaluation();

  const onCreateNode = (
    config: NodeConfig<StudioNodeType, StudioNodeData>,
    props: CreateNodeProps,
  ) => {
    onCreateStudioNode(config.createNodeData(), props);
  };

  const isPropertiesMenuEnabled = useFeatureFlag(FeatureFlags.PropertiesMenu);
  const shouldShow = (node: NodeConfig<StudioNodeType, StudioNodeData>) => {
    if (isPropertiesMenuEnabled && node.type === StudioNodeType.EpisodeSetup) {
      return false;
    }

    return (
      (!node.episodeRef || node.episodeRef === episodeRef) &&
      (!node.featureFlag || getFlagEvaluation(node.featureFlag))
    );
  };

  return (
    <StyledMenu id={CONTEXT_MENU_ID} theme="dark">
      {rootNodes.filter(shouldShow).map((config) => (
        <Item
          key={config.type}
          onClick={({ props }) => onCreateNode(config, props)}
        >
          <ItemContainer>
            <Icon name={config.icon} size={rawDimensions.size16} />
            {config.name}
          </ItemContainer>
        </Item>
      ))}
      {Object.entries(groupNodes)
        .filter(([, configs]) => configs.filter(shouldShow).length)
        .map(([group, configs]) => (
          <Submenu key={group} label={group}>
            {configs.filter(shouldShow).map((config) => (
              <Item
                key={config.type}
                onClick={({ props }) => onCreateNode(config, props)}
              >
                <ItemContainer>
                  <Icon name={config.icon} size={rawDimensions.size16} />
                  {config.name}
                </ItemContainer>
              </Item>
            ))}
          </Submenu>
        ))}
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  ${textStyles.label.lb14sb}
  --contexify-menu-bgColor: #000000;
  --contexify-separator-color: rgba(255, 255, 255, 0.05);
  --contexify-menu-padding: 0px;
  --contexify-menu-negatePadding: 0px;
  --contexify-item-color: #fff;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: rgba(255, 255, 255, 0.05);
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-activeArrow-color: #fff;

  padding: ${dimensions.size0};

  .contexify_item {
    padding: ${dimensions.size0};
    border-radius: ${dimensions.size0};
  }

  .contexify_itemContent {
    padding: ${dimensions.size12} ${dimensions.size16};
    border-radius: ${dimensions.size0};
  }

  .contexify_item:not(.contexify__item--disabled):hover
    > .contexify_itemContent,
  .contexify_item:not(.contexify__item--disabled):focus
    > .contexify_itemContent {
    border-radius: ${dimensions.size0};
  }
`;

const ItemContainer = styled.div`
  display: flex;
  gap: ${dimensions.size8};
  align-items: center;
`;
