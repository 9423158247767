import { CloseButton } from '@chakra-ui/react';
import {
  DataType,
  PropertyScope,
  RpgConfig,
  RpgConfigProperty,
  Value,
  ValueType,
} from '@common/studio-types';
import { TextInput } from '@maestro/components';
import {
  Menu,
  MenuDropdownButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@maestro/components/menu/Menu';
import { dimensions, textStyles } from '@maestro/styles';
import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { ValueBadge } from './ValueBadgeV2';

type Props = {
  rpgConfig: RpgConfig;
  onChange: (value: Value) => void;
  value?: Value;
  referenceValue?: Value;
  dataTypes?: DataType[];
  isNullable?: boolean;
};

function dataType(dataType: DataType): string {
  if (dataType === DataType.String) {
    return 'text';
  } else if (dataType === DataType.Number) {
    return 'number';
  } else if (dataType === DataType.Enum) {
    return 'choice';
  }

  return '';
}

export const PropertyInput: React.FC<Props> = (props) => {
  const { rpgConfig, dataTypes, referenceValue } = props;
  const [search, setSearch] = useState('');
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onClear = (e: React.MouseEvent) => {
    setSearch('');
    e.stopPropagation();
  };

  const filteredProperties = rpgConfig.properties.filter(
    (property) =>
      property.name.toLowerCase().includes(search.toLowerCase()) &&
      (!dataTypes || dataTypes.includes(property.config.dataType)) &&
      (!referenceValue || property.config.dataType === referenceValue.dataType),
  );

  const filteredSeriesProperties = filteredProperties.filter(
    (property) => property.scope === PropertyScope.Series,
  );

  const filteredEpisodeProperties = filteredProperties.filter(
    (property) => property.scope === PropertyScope.Episode,
  );

  const onSelectProperty = (property: RpgConfigProperty) => {
    props.onChange({
      type: ValueType.Property,
      ref: property.id,
      dataType: property.config.dataType,
      enumRef:
        property.config.dataType === DataType.Enum
          ? property.config.enumRef
          : undefined,
    });
  };

  const onSetNullable = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();

    if (props.isNullable) {
      props.onChange(undefined!);
    }
  };

  return (
    <StyledMenu>
      <MenuDropdownButton>
        <DropdownContainer>
          {props.value?.type === ValueType.Property && props.value.ref ? (
            <ValueBadge value={props.value} />
          ) : null}
          {props.isNullable && props.value && (
            <CloseButton onClick={onSetNullable} />
          )}
        </DropdownContainer>
      </MenuDropdownButton>
      <MenuList>
        <Searcher>
          <TextInput
            value={search}
            showRemoveButton
            onRemove={onClear}
            placeholder="Search"
            onClick={(e) => e.stopPropagation()}
            onChange={onChangeSearch}
          />
        </Searcher>

        {filteredSeriesProperties.length > 0 && (
          <MenuGroup>Series properties</MenuGroup>
        )}
        {filteredSeriesProperties.map((property) => (
          <MenuItem
            key={property.id}
            onClick={() => onSelectProperty(property)}
          >
            <PropertyItemContainer>
              <PropertyName>{property.name}</PropertyName>
              <PropertyType>{dataType(property.config.dataType)}</PropertyType>
            </PropertyItemContainer>
          </MenuItem>
        ))}

        {filteredEpisodeProperties.length > 0 && (
          <MenuGroup>Episode properties</MenuGroup>
        )}
        {filteredEpisodeProperties.map((property) => (
          <MenuItem
            key={property.id}
            onClick={() => onSelectProperty(property)}
          >
            <PropertyItemContainer>
              <PropertyName>{property.name}</PropertyName>
              <PropertyType>{dataType(property.config.dataType)}</PropertyType>
            </PropertyItemContainer>
          </MenuItem>
        ))}
      </MenuList>
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  flex: 1;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  align-items: center;
  justify-content: space-between;
  padding: ${dimensions.size4};
`;

const Searcher = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  padding: ${dimensions.size0} ${dimensions.size8};
  margin-bottom: ${dimensions.size8};
`;

const PropertyItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  justify-content: space-between;
  width: 100%;
`;

const PropertyName = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const PropertyType = styled.div`
  ${textStyles.label.lb14sb}
  color: ${({ theme }) => theme.colors.text.body};
`;
