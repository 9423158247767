import { breakpoints, dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

export const SectionContainer = styled.div<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap ?? dimensions.size24};
  width: 100%;
  background: transparent;
  padding: ${dimensions.size48};

  @media ${breakpoints.tablet} {
    padding: ${dimensions.size16};
  }
`;

export const Section = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size24};
  align-items: flex-start;
  width: 100%;
  background: transparent;

  @media ${breakpoints.tablet} {
    gap: ${dimensions.size12};
  }
`;

export const SectionTitle = styled.div`
  ${textStyles.title.t20b}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.header};
`;

export const SectionCard = styled.div`
  ${textStyles.body.b16m}
  width: 100%;
  padding: ${dimensions.size24};
  color: ${({ theme }) => theme.colors.text.body};
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};

  @media ${breakpoints.tablet} {
    padding: ${dimensions.size12};
  }
`;
