import type { OnboardingCompleteNodeData } from '@common/studio-types';
import type { GameState } from '../game';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';

export const onboardingComplete = (
  block: GameNode<OnboardingCompleteNodeData>,
  state: GameState,
): ActionResult => {
  return {
    messages: [
      {
        type: 'onboarding-complete',
        message: block.text.trim(),
      },
    ],
    state,
  };
};
