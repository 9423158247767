import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { Icon, TextInput, Textarea } from '@maestro/components';
import { useFeatureFlagEvaluation } from '@maestro/feature-flags';
import { FC, useState } from 'react';
import { useGenerateWorldItems } from '../../hooks/useGenerateWorldItems';
import {
  GenerateNPCResponse,
  WorldCharacter,
  WorldLocation,
} from '../../types';
import {
  GeneratorFormContainer,
  GeneratorFormHeader,
  GeneratorItemBody,
} from './GeneratorComponents';

type Props = {
  story: string;
  submitPressed?: boolean;
  characters: WorldCharacter[];
  locations: WorldLocation[];
  onDelete: () => void;
  character: WorldCharacter;
  onChange: (character: WorldCharacter) => void;
  index: number;
  onGeneratingContent: (generating: boolean) => void;
};

export const CharacterForm: FC<Props> = ({
  story,
  character,
  onDelete,
  onChange,
  index,
  submitPressed,
  onGeneratingContent,
}) => {
  const [isGeneratingCharacter, setIsGeneratingCharacter] =
    useState<boolean>(false);
  const { generateWorldItems } = useGenerateWorldItems();
  const { getFlagEvaluation } = useFeatureFlagEvaluation();
  const isGenerateWithAiEnabled = getFlagEvaluation('ENABLE-GENERATE-WITH-AI');

  const generateWithAi = async () => {
    onGeneratingContent(true);
    setIsGeneratingCharacter(true);

    const { items } = await generateWorldItems({
      count: 1,
      itemType: 'character',
      story,
      worldContext: {},
    }).finally(() => {
      onGeneratingContent(false);
      setIsGeneratingCharacter(false);
    });

    const character = items
      .characters[0] as GenerateNPCResponse['characters'][0];

    onChange({
      name: character.name,
      traits: `${character.physicalDescription} ${character.fears} ${character.personality}`,
      goals: `${character.goals} ${character.motivations}`,
      relationships: character.relationships,
    });
  };

  const canRegenerate =
    character.name ||
    character.goals ||
    character.relationships ||
    character.traits;

  return (
    <GeneratorFormContainer>
      <GeneratorFormHeader>
        <Text fontWeight={'semibold'} fontSize={18}>
          Character {index + 1}
        </Text>
        <ButtonGroup>
          <Button
            isDisabled={isGeneratingCharacter}
            onClick={() => onDelete()}
            variant="ghost"
            size="sm"
            leftIcon={<Icon name="trash" size={16} />}
          >
            Delete
          </Button>
          {isGenerateWithAiEnabled && (
            <Button
              onClick={generateWithAi}
              isLoading={isGeneratingCharacter}
              type="submit"
              size="sm"
              variant="ghost"
            >
              {canRegenerate ? 'Regenerate' : 'Generate'} with AI
            </Button>
          )}
        </ButtonGroup>
      </GeneratorFormHeader>
      <GeneratorItemBody>
        <FormControl mb="3" isInvalid={submitPressed && !character.name}>
          <FormLabel>Character name</FormLabel>
          <TextInput
            maxCharacters={100}
            value={character.name}
            onChange={(e) => onChange({ ...character, name: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !character.name && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl mb="3" isInvalid={submitPressed && !character.traits}>
          <FormLabel>What defining traits does this character have?</FormLabel>
          <Textarea
            maxCharacters={2000}
            value={character.traits}
            onChange={(e) => onChange({ ...character, traits: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !character.traits && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl mb="3" isInvalid={submitPressed && !character.goals}>
          <FormLabel>
            What are this character's biggest goals or aspirations?
          </FormLabel>
          <Textarea
            maxCharacters={2000}
            value={character.goals}
            onChange={(e) => onChange({ ...character, goals: e.target.value })}
          />
          <FormErrorMessage>
            {submitPressed && !character.goals && 'This field is required'}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          mb="3"
          isInvalid={submitPressed && !character.relationships}
        >
          <FormLabel>
            What important interpersonal relationships does this character have
            with other characters?
          </FormLabel>
          <Textarea
            maxCharacters={2000}
            value={character.relationships}
            onChange={(e) =>
              onChange({ ...character, relationships: e.target.value })
            }
          />
          <FormErrorMessage>
            {submitPressed &&
              !character.relationships &&
              'This field is required'}
          </FormErrorMessage>
        </FormControl>
      </GeneratorItemBody>
    </GeneratorFormContainer>
  );
};
