import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useGenerateWorldTitleAndOverview } from '../../hooks/useGenerateWorldTitleAndOverview';
import { CreateWorldFields } from '../../types';

type Fields = {
  genre: string;
  tone: string;
  era: string;
  context: string;
};

type Props = {
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  world: CreateWorldFields;
  onChange: (world: CreateWorldFields) => void;
  onClose?: () => void;
  setLoadingState?: (loading: boolean) => void;
};

export const GenerateWorldBibleWithAiForm: FC<Props> = (props) => {
  const { submitButtonRef, world, onChange, onClose, setLoadingState } = props;
  const [isInteractingWithAi, setIsInteractingWithAi] =
    useState<boolean>(false);

  const toast = useToast();

  const { generateWorldTitleAndOverview } = useGenerateWorldTitleAndOverview();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Fields>();

  const onGenerateClick: SubmitHandler<Fields> = async (data) => {
    try {
      setIsInteractingWithAi(true);
      setLoadingState?.(true);

      toast({
        title: 'Making magic with AI 🧙‍♂️',
        status: 'loading',
        duration: null,
        isClosable: false,
        id: 'world-bible-generate-toast',
      });

      const { title, overview } = await generateWorldTitleAndOverview(data);

      onChange({ ...world, title, story: overview });

      toast.close('world-bible-generate-toast');

      toast({
        title: 'World title and story generated',
        description: 'Your world bible has been generated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose?.();
    } catch (error) {
      toast.close('world-bible-generate-toast');

      toast({
        title: 'Error generating world bible',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsInteractingWithAi(false);
      setLoadingState?.(false);
    }
  };

  return (
    <form method="dialog" onSubmit={handleSubmit(onGenerateClick)}>
      <FormControl mb="3" isInvalid={!!errors?.genre}>
        <FormLabel>Genre of World</FormLabel>
        <Input
          id="genre"
          type="text"
          {...register('genre', { required: 'A genre is required' })}
        />
        <FormErrorMessage>{`${errors.genre?.message}`}</FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!errors?.tone}>
        <FormLabel>Tone and Style</FormLabel>
        <Input
          id="tone"
          type="text"
          {...register('tone', { required: 'A tone is required' })}
        />
        <FormErrorMessage>{`${errors.tone?.message}`}</FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!errors?.era}>
        <FormLabel>Historical Era</FormLabel>
        <Input
          id="era"
          type="text"
          {...register('era', { required: 'A era is required' })}
        />
        <FormErrorMessage>{`${errors.era?.message}`}</FormErrorMessage>
      </FormControl>
      <FormControl mb="3" isInvalid={!!errors?.context}>
        <FormLabel>What kind of world do you want to create?</FormLabel>
        <Textarea
          id="traits"
          rows={10}
          {...register('context', { required: 'Traits are required' })}
        />
        <FormErrorMessage>{`${errors.context?.message}`}</FormErrorMessage>
      </FormControl>
      <Button
        display={'none'}
        isLoading={isInteractingWithAi}
        type="submit"
        variant="primary"
        ref={submitButtonRef}
      />
    </form>
  );
};
