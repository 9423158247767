import {
  DataType,
  type RpgConfig,
  type StaticValue,
  getEnumOptions,
} from '@common/studio-types';
import { NumericInput, TextInput } from '@maestro/components';
import React from 'react';
import { EnumDropdown } from './EnumDropdown';

type Props = {
  value: StaticValue;
  onChange: (value: StaticValue) => void;
  rpgConfig: RpgConfig;
};

export const StaticValueInput: React.FC<Props> = ({
  value,
  onChange,
  rpgConfig,
}) => {
  if (value.dataType === DataType.Number) {
    return (
      <NumericInput
        flex={1}
        placeholder="Value"
        value={value.value}
        min={-1000}
        onChange={(valueAsNumber) =>
          onChange({ ...value, value: valueAsNumber })
        }
      />
    );
  }

  if (value.dataType === DataType.String) {
    return (
      <TextInput
        placeholder="Type value"
        value={value.value}
        onChange={(e) => onChange({ ...value, value: e.target.value })}
      />
    );
  }

  if (value.dataType === DataType.Enum) {
    const options = getEnumOptions(value.enumRef, rpgConfig.properties);

    return (
      <EnumDropdown
        value={value.ref}
        options={options}
        onChange={(ref) => onChange({ ...value, ref })}
      />
    );
  }
};
