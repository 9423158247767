import { Button, Switch } from '@chakra-ui/react';
import type {
  SingleSelectNodeData,
  SingleSelectOption,
} from '@common/studio-types';
import { Icon } from '@maestro/components/Icon';
import { Textarea } from '@maestro/components/Textarea';
import { TextInput } from '@maestro/components/TextInput';
import { dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { toNodeId } from '../../toId';
import { StudioNodeFormComponent } from '../Node.types';

export const SingleSelectForm: StudioNodeFormComponent<
  SingleSelectNodeData
> = ({ data, onChange }) => {
  const addNewOption = () => {
    const newOption: Omit<SingleSelectOption, 'nextNodeId'> = {
      id: toNodeId(),
      text: '',
    };

    onChange({
      ...data,
      options: [...data.options, newOption as SingleSelectOption],
    });
  };

  const removeOption = (optionId: string) => {
    onChange({
      ...data,
      options: data.options.filter((option) => option.id !== optionId),
    });
  };

  return (
    <Container>
      <Hint>
        Players can choose different narrative branches. You can create up to
        three different paths.
      </Hint>
      <Field label="Narrator prompt">
        <Textarea
          placeholder="Write a narrator prompt here"
          maxCharacters={100}
          autoFocus={!data.prompt}
          value={data.prompt}
          onChange={(e) => onChange({ ...data, prompt: e.target.value })}
        />
      </Field>

      <Field label="Player choice">
        <OptionsContainer>
          {data.options.map((option, index) => (
            <OptionContainer key={option.id}>
              <TextInput
                maxCharacters={50}
                showRemoveButton={index > 0}
                onRemove={() => removeOption(option.id)}
                value={option.text}
                onChange={(e) => {
                  const newOptions = data.options.map((opt, i) =>
                    i === index ? { ...opt, text: e.target.value } : opt,
                  );
                  onChange({ ...data, options: newOptions });
                }}
              />
            </OptionContainer>
          ))}
        </OptionsContainer>
        <Button
          fontSize="14px"
          variant="default"
          onClick={addNewOption}
          leftIcon={<Icon name="plus" size={16} />}
        >
          Add option
        </Button>
      </Field>

      <CheckboxContainer>
        <div>Hide player choice option if selected before</div>
        <Switch
          size="lg"
          isChecked={data.hideSelectedOptions}
          onChange={(e) =>
            onChange({ ...data, hideSelectedOptions: e.target.checked })
          }
        />
      </CheckboxContainer>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CheckboxContainer = styled.div`
  ${textStyles.body.b14m};
  color: ${({ theme }) => theme.colors.text.body};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${dimensions.size8};
  padding: ${dimensions.size0} ${dimensions.size0} ${dimensions.size24}
    ${dimensions.size0};
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  border-radius: ${dimensions.size4};
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
